import service from '@/utils/request'

// @Tags WarningRole
// @Summary 创建WarningRole
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningRole true "创建WarningRole"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devWarningRole/createWarningRole [post]
export const createWarningRole = (data) => {
  return service({
    url: '/devWarningRole/createWarningRole',
    method: 'post',
    data
  })
}

// @Tags WarningRole
// @Summary 删除WarningRole
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningRole true "删除WarningRole"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devWarningRole/deleteWarningRole [delete]
export const deleteWarningRole = (data) => {
  return service({
    url: '/devWarningRole/deleteWarningRole',
    method: 'delete',
    data
  })
}

// @Tags WarningRole
// @Summary 删除WarningRole
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除WarningRole"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /devWarningRole/deleteWarningRole [delete]
export const deleteWarningRoleByIds = (data) => {
  return service({
    url: '/devWarningRole/deleteWarningRoleByIds',
    method: 'delete',
    data
  })
}

// @Tags WarningRole
// @Summary 更新WarningRole
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.WarningRole true "更新WarningRole"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /devWarningRole/updateWarningRole [put]
export const updateWarningRole = (data) => {
  return service({
    url: '/devWarningRole/updateWarningRole',
    method: 'put',
    data
  })
}

// @Tags WarningRole
// @Summary 用id查询WarningRole
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query model.WarningRole true "用id查询WarningRole"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /devWarningRole/findWarningRole [get]
export const findWarningRole = (params) => {
  return service({
    url: '/devWarningRole/findWarningRole',
    method: 'get',
    params
  })
}

// @Tags WarningRole
// @Summary 分页获取WarningRole列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data query request.PageInfo true "分页获取WarningRole列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /devWarningRole/getWarningRoleList [get]
export const getWarningRoleList = (params) => {
  return service({
    url: '/devWarningRole/getWarningRoleList',
    method: 'get',
    params
  })
}
